import './App.css';
import fitimg from './img/fitness_PNG155.png';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoIosCheckbox,IoIosTrophy, IoIosStar } from "react-icons/io";
import { GiWeightLiftingUp } from "react-icons/gi";
import { ImPower } from "react-icons/im";
import { IoSpeedometerSharp } from "react-icons/io5";

function App() {
  return (
    <div className="App">
      <nav>
        <ul id='navbar'>
          <li><a href="#home">Home</a></li>
          <li><a href="#philosophy">Philosophy</a></li>
          <li><a href="#membership">Memberships</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>

      <div id="home">
        <p id='hellointro'>Upgrade to THE BEST version of YOU</p>
        <h1 id='hellotitle'>
          High Intensity Functional Training
        </h1>
        <div id='frontbuttons'>
          <a href="#membership"><button type="button">Sign Up Today</button></a>
          <a href="#program"><button type="button">Learn More</button></a>
        </div>
      </div>

      <div id='philosophy'>
        <div id='philosophybg'>
          <h2 id='philosophyhead'>
            Fitness Philosophy
          </h2>
          <div className='card-container-one'>
            <div className='large-card'>
              <ul>
                <li><span className='bronzetitle'><IoSpeedometerSharp id='speed'/> Speed</span></li>
                <li>Learning the fundamentals to perfect running technique which will improve your speed</li>
                <li>Improve agility and stamina doing drills</li>
                <li>"Speed Kills" as they say</li>
              </ul>
            </div>
            <div className='large-card'>
              <ul>
                <li><span className='silvertitle'><ImPower id='power'/> Power</span></li>
                <li>Learn to work on the fundamentals of strength and speed which makes up power</li>
                <li>Training with the focus on power will greatly improve performance</li>
                <li>Decrease risk of injury</li>
              </ul>
            </div>
            <div className='large-card'>
              <ul>
                <li><span className='goldtitle'><GiWeightLiftingUp id='strength'/> Strength</span></li>
                <li>Increase your physical output with a focus on strength training</li>
                <li>Improve your body functionality by making it stronger</li>
                <li>Increase longevity of your body</li>
              </ul>
            </div>
          </div>
        </div>

        <div id='fitpic'>
          <img src={fitimg} alt="Fitness Pic" />
        </div>
      </div>



      <div id='membership'>
        <div id='membershipbg'>
          <h2 id='membershiphead'>
            Memberships
          </h2>

          <h3 className='membershiptitle'>Monthly Packages:</h3>

          <div className='card-container-two'>
            <div className='small-card'>
              <ul>
                <li><span className='bronzetitle'><IoIosStar id='bronze-star'/> Bronze:</span> $27.99</li>
                <li>3 Days per week Access</li>
                <li>Guaranteed Results</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='silvertitle'><IoIosStar id='silver-star'/> Silver:</span> $49.99</li>
                <li>4 Days per week Access</li>
                <li>Nutrition Planning</li>
                <li>Guaranteed Results</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='goldtitle'><IoIosStar id='gold-star'/> Gold:</span> $79.99</li>
                <li>Unlimited Access</li>
                <li>Nutrition Planning</li>
                <li>Guaranteed Results</li>
                <li>Free Swag</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='setuptitle'>No Commitment <IoIosTrophy id='trophy'/></span></li>
                <li>Cancel anytime and only pay the month</li>
                <li>No discount</li>
              </ul>
            </div>
          </div>

          <h3 className='membershiptitle'>Yearly Packages:</h3>

          <div className='card-container-two'>
            <div className='small-card'>
              <ul>
                <li><span className='bronzetitle'><IoIosStar id='bronze-star'/> Bronze:</span> $275.99</li>
                <li>3 Days per week Access</li>
                <li>Guaranteed Results</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='silvertitle'><IoIosStar id='silver-star'/> Silver:</span> $459.99</li>
                <li>4 Days per week Access</li>
                <li>Nutrition Planning</li>
                <li>Guaranteed Results</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='goldtitle'><IoIosStar id='gold-star'/> Gold:</span> $809.99</li>
                <li>Unlimited Access</li>
                <li>Nutrition Planning</li>
                <li>Guaranteed Results</li>
                <li>Free Swag</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='setuptitle'>Year Commitment <IoIosTrophy id='trophy'/></span></li>
                <li>Cannot cancel without losing the full amount</li>
                <li>Discounted package</li>
                <li>Best packages for serious clients</li>
              </ul>
            </div>
          </div>
          <p id='supporttext'>
            * Custom packages are available. Please contact for more information.
          </p>
        </div>
      </div>

      <div id='about'>
        <div id='aboutbg'>
          <div id='abouthead'>
            <h2>About Fitness Site</h2>
          </div>
          <p>
            We specialize in functional performance training that will improve the client greatly. Using the program that we have developed for years with proven results, clients will increase their strength, speed and power.
          </p>
          <p>
            We believe in challenging and pushing the body to it's limits in a healthy matter to increase athletic output.
          </p>
        </div>
      </div>

      <div id='testimonials'>
        <div id='testimonialsbg'>
          <div id='testimonialshead'>
            <h2>Client Testimonials</h2>
          </div>
          <div id='testimonialsbody'>
            <div className='testimonial'>
              <h4>John <IoIosCheckbox id='check'/></h4>
              <p>
                These guys are the real deal here! In just 12 weeks they turned me from an average joe to an athletic machine.
              </p>
            </div>
            <div className='testimonial'>
              <h4>Susan <IoIosCheckbox id='check'/></h4>
              <p>
                My body was sore and tired all the time and I am only 28 so I knew I needed to improve. Since joining here I have been able to lose some excess weight and improve my cardiovascular health along with get into shape!
              </p>
            </div>
            <div className='testimonial'>
              <h4>Roger <IoIosCheckbox id='check'/></h4>
              <p>
                Wouldn't trust anyone else with helping me take care of myself! These guys have truly changed my life for the better.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='foot'>
        <div className='card-container-one'>
          <ul className='contact-card'>
            <li><h2 id='contact-head'>Fitness Gym</h2></li>
            <li>
              123 Main St, Grimsby, ON
              L0X1X1</li>
          </ul>
          <ul className='contact-card'>
            <li><h2 id='contact-head'>Follow Us</h2></li>
            <div id='social-links'>
              <li><a href='https://facebook.com'><FaFacebook id='facebook' /></a> <a href='https://instagram.com'><FaInstagram id='instagram'/></a> <a href='https://twitter.com'><FaXTwitter /></a></li>
            </div>
          </ul>
          <ul className='contact-card'>
          <li><h2 id='contact-head'>Contact Us</h2></li>
            <li>
              Damon Morgan - Owner
            </li>
            <li>
              Email: Owner@Fitness.ca
            </li>
            <li>
              905-111-2233
            </li>
          </ul>
          <li>Site developed by: <a href='https://damonmorgan.net'>Damon M</a></li>

          <li>Fitness Site © 2024</li>
        </div>
      </div>
    </div>
  );
}

export default App;
